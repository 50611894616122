<template>
<div class="table-add" v-loading.fullscreen.lock="loading">
    <div class="table-add-header">
        <h1>Edit Footer page</h1>
        <div class="flex mt-20">
            <button class="table-lang-button" type="button" @click="langName='ua'" :class="langName=='ua'?'active':''">UA</button>
            <button class="table-lang-button ml-10" type="button" @click="langName='en'" :class="langName=='en'?'active':''">ENG</button>
        </div>
        <div class="add-inputs" style="max-width:1000px;">
            <el-form ref="pagesForm1" :model="Pages" :rules="PagesRules1" v-show="langName=='ua'">
                <div class="form-au" >
                    <div class="item mt-20">
                        <p>Button Title</p>
                        <el-form-item prop="data.button_ua">
                            <el-input v-model="Pages.data.button_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Phone</p>
                        <el-form-item prop="data.phone">
                            <el-input v-model="Pages.data.phone"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Work time</p>
                        <el-form-item prop="data.adress_ua">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.adress_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Left Text</p>
                        <el-form-item prop="data.text_left_ua">
                            <el-input v-model="Pages.data.text_left_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Right Text</p>
                        <el-form-item prop="data.text_right_ua">
                            <el-input v-model="Pages.data.text_right_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Right Text Url Name</p>
                        <el-form-item prop="data.text_right_en_url">
                            <el-input v-model="Pages.data.text_right_name_ua"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Right Text Url</p>
                        <el-form-item prop="data.text_right_en_url">
                            <el-input v-model="Pages.data.text_right_url"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Facebook</p>
                        <el-form-item prop="data.facebook">
                            <el-input v-model="Pages.data.facebook"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p>Instagram</p>
                        <el-form-item prop="data.instagram">
                            <el-input v-model="Pages.data.instagram"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Twitter</p>
                        <el-form-item prop="data.twitter">
                            <el-input v-model="Pages.data.twitter"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.logo_ua">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'logo_ua')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.logo_ua&&Pages.files.logo_ua.name? Pages.files.logo_ua.name: Pages.files.logo_ua ? Pages.files.logo_ua :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                </div>
            </el-form>
            <el-form ref="pagesForm2" :model="Pages" :rules="PagesRules2" v-show="langName=='en'">
                 <div class="form-au" >
                    <div class="item mt-20">
                        <p>Button Title</p>
                        <el-form-item prop="data.button_en">
                            <el-input v-model="Pages.data.button_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <p>Work time</p>
                        <el-form-item prop="data.adress_en">
                            <el-input type="textarea" :rows="4" v-model="Pages.data.adress_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Left Text</p>
                        <el-form-item prop="data.text_left_en">
                            <el-input v-model="Pages.data.text_left_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item mt-20">
                        <p> Right Text</p>
                        <el-form-item prop="data.text_right_en">
                            <el-input v-model="Pages.data.text_right_en"></el-input>
                        </el-form-item>
                    </div>  
                    <div class="item mt-20">
                        <p> Right Text Url Name</p>
                        <el-form-item prop="data.text_right_en_url">
                            <el-input v-model="Pages.data.text_right_name_en"></el-input>
                        </el-form-item>
                    </div>
                    <div class="item">
                        <el-form-item prop="files.logo_en">
                            <div class="upload-image">
                                <button class="bg-red">
                                    Image Upload
                                    <input type="file" name="image" @change="UploadIMage($event ,'logo_en')" accept="image/*">
                                </button>
                                <p :class="erros.icon?'c-red':''"> {{Pages.files.logo_en&&Pages.files.logo_en.name? Pages.files.logo_en.name: Pages.files.logo_en ? Pages.files.logo_en :'Upload Image'}} </p>
                            </div>
                            <p v-if="erros.icon" class="error-text">Upload image</p>
                        </el-form-item>
                    </div>

                </div>
            </el-form>
                <div class="save-buttons">
                    <button class="button" type="button" @click="$router.push('/admin/statpages')"> {{$t('back')}} </button>
                    <button class="button save ml-10" type="button" @click="PostStock()"> Edit</button>
                </div> 
        </div>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            checked: false,
            langName: 'ua',
            fileList: [],
            value1: '',
            form: {
                name: ''
            },
            loading: false,
            erros: {
                image: false,
                en: false,
                ua: false
            },
            Pages: {
                key: "/footer",
                data: {
                    button_ua:'',
                    button_en:'', 
                    adress_ua:'',
                    adress_en:'',
                    phone:'',
                    facebook:'',
                    instagram:'',
                    twitter:'',
                    text_left_ua:'',
                    text_left_en:'',
                    text_right_ua:'',
                    text_right_url:'',
                    text_right_en:'',
                    text_right_en_url:'',
                    text_right_name_en:'',
                    text_right_name_ua:''

                },
                files: {
                    logo_ua: '',
                    logo_en: '',
                }
            },
            PagesRules1: {      
                data: {
                    button_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    url:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    adress_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    phone:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    facebook:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    instagram:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    twitter:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    text_left_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    text_right_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    text_right_url:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    text_right_name_ua:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],

                },

                files: {
                    logo_ua: [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                }

            },
            PagesRules2:{
                data: { 
                    button_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    adress_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],   
                    text_left_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                    text_right_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }], 
                    text_right_name_en:[{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],

                },
                files: { 
                    logo_en:   [{
                        required: true,
                        message: 'Будь ласка, введіть інформацію',
                        trigger: 'blur'
                    }],
                }
            }
        }

    },

    methods: {
        UploadIMage(event, name) {
            this.Pages.files[`${name}`] = event.target.files[0]
        },
        PostStock() {

            this.$refs.pagesForm1.validate((valid1) => {
                if (valid1) {
                    this.$refs.pagesForm2.validate((valid2) => {
                        if(valid2){
                            this.loading = true
                        let files = new FormData();
                        var pageData = {}
                        if (typeof this.Pages.data != 'string') {
                            pageData = JSON.stringify(this.Pages.data)
                        }
                        files.append('key', this.Pages.key)
                        files.append('data', pageData)
                        for (let i of Object.keys(this.Pages.files)) {
                            if (this.Pages.files[i].name) {
                                files.append(`files[${i}]`, this.Pages.files[i]);
                            }
                        }

                        this.axios.post(this.Pages.id ? `/admin/statpage/${this.Pages.id}` : '/admin/statpage', files).then(res => {
                                console.log(res)
                                this.$router.push('/admin/statpages')
                                this.$notify({
                                    title: 'Успешный',
                                    message: 'Информация успешно сохранена',
                                    type: 'success'
                                });
                                this.$store.dispatch("get_statpages");
                            })
                            .catch(error => {
                                this.erorrsData = error.response.data.message
                                let ERRORS = error.response.data.errors;
                                let keys = Object.keys(ERRORS);
                                let error_msg = ERRORS[keys[0]];
                                console.log(error_msg[0])
                                this.$notify.error({
                                    title: 'Произошла ошибка',
                                    message: error_msg ? error_msg[0] : "Произошла ошибка",
                                });

                            })
                            .finally(() => {
                                this.loading = false
                            })
                        }
                        else{
                            this.langName='en'
                        }
                        
                    })
                }
                else{
                    this.langName='ua'
                } 
                
                 
            })

        },
        GetPages() {
            this.loading = true
            this.axios.get('/admin/statpage')
                .then(res => {
                    var data = res.data.data
                    var home = data.filter(a => {
                        return a.key === '/footer'
                    })
                    if (home[0]) {
                        this.Pages.id = home[0].id
                        this.Pages.key = home[0].key
                        this.Pages.data = home[0].data
                        for (let i of Object.keys(home[0].files)) {
                            this.Pages.files[i] = home[0].files[i]
                        }

                    }
                })
                .finally(() => {
                    this.loading = false
                })
        }

    },
    mounted() {
        this.GetPages()
    }
}
</script>

<style lang="scss" scoped>
.date-line {
    width: 30px;
    height: 3px;
    background: rgba(69, 72, 81, 0.8);
    border-radius: 5px;
    margin: 0px 10px;
}

.save-buttons {
    padding-top: 20px;
}

.upload-image {
    width: 100%;
    display: flex;
    align-items: center;

    p {
        margin-left: 10px;
    }

    button {
        width: 200px;
        position: relative;
        overflow: hidden;
        padding: 10px 15px;
        border-radius: 5px;
        color: var(--white-color);
        font-family: Stem-Medium;
        font-size: 1rem;
        line-height: 1.125rem;
        border: none;
        color: var(--black);

        input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: 1;
        }
    }
}

.admin-h2-title {
    margin: 20px 0px 10px 0px;
    font-family: Stem-Medium;
    color: var(--other-black);
}

.error-text {
    color: red;
    font-size: 0.875rem;
    margin-top: 5px;
}

.c-red {
    color: red !important;
}

.add-inputs {
    h1 {
        margin: 20px 0px;
        font-size: 24px;
    }
}
</style>
